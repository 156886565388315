import { onAuthStateChanged, signInAnonymously, User } from "firebase/auth";
import { useAtom } from "jotai/index";
import { userAtom } from "../atom/userAtom";
import { useEffect } from "react";
import { auth } from "../firebase/config";

export const useUserInitialization = (): User | "loading" => {
  const [user, setUser] = useAtom(userAtom);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user != null) {
        setUser(user);
      } else {
        signInAnonymously(auth).then((userCredential) => {
          setUser(userCredential.user);
        });
      }
    });
  }, []);

  return user;
};
