import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Game } from "../models/Game";
import { gameRepository } from "../repositories/GameRepository";
import { Waiting } from "../components/room/Waiting";
import { FullscreenLoading } from "../components/FullscreenLoading";
import { GameView } from "../components/gameView";

export const Room: FC = () => {
  const { gameId } = useParams();
  const [game, setGame] = useState<Game>();
  const gameManager = useMemo(
    () => ({
      async postPrompt(text: string): Promise<void> {
        if (game == null) return;
        return gameRepository.postPrompt(game, text);
      },
      async postAnswer(text: string): Promise<void> {
        if (game == null) return;
        return gameRepository.postAnswer(game, text);
      },
      async skip(): Promise<void> {
        if (game == null) return;
        return gameRepository.skip(game);
      },
    }),
    [game]
  );

  useEffect(() => {
    if (gameId == null) return;
    const unsubscribe = gameRepository.listen(gameId, (newGame) => {
      if (newGame == null) return;
      setGame(newGame);
    });
    return () => unsubscribe();
  }, [gameId, setGame]);

  if (game == null) return <FullscreenLoading />;

  const props = {
    game,
  };
  if (game.state === "waiting") return <Waiting {...props} />;
  if (["prompting", "generating", "thinking", "finished"].includes(game.state))
    return <GameView {...props} gameManager={gameManager} />;
  return null;
};
