import { FC } from "react";
import styled from "styled-components";
import { Text } from "../element/Text";

export const MultiPlayerGameRule: FC = () => {
  return (
    <Root>
      <h2>遊び方</h2>
      <Text>
        お題にもとづき、ゲームの親（画伯）がAIに指示をあたえ、画像を生成します。これを他のプレイヤーが、当てるというゲームです。
        <br />
        当てると点数を得ることができ、最終的な点数がもっとも高かったプレイヤーが勝ちです。
      </Text>
      <h3>ゲームの流れ</h3>
      <ol>
        <li>
          まずは、画伯が選出されます。画伯だけがお題の内容を見ることができます。
        </li>
        <li>
          画伯がお題を表現する絵を、どのようにAIに伝えるかを考え、指示を入力し「生成」します。
        </li>
        <li>
          AIが指示をもとに、画像を生成します。これには数十秒ほど要する場合があります。
        </li>
        <li>
          生成された画像が表示されたら、プレイヤーたちはお題の内容を、制限時間内に予想して当てます。
        </li>
        <li>
          見事的中したら、的中させたプレイヤーと画伯に点数があたえられます。
          なお、誤った回答をしてもペナルティはありません。
        </li>
        <li>
          正解が出たら次のプレイヤーへと画伯が移ります。全プレイヤーが画伯を行い終わったら、ゲームが終了します。
        </li>
      </ol>
      <h3>点数について</h3>
      <Text>画伯</Text>
      <ul>
        <li>自分の生成した画像を当ててもらうと、2点獲得します。</li>
        <li>
          さらに、このとき:
          <ul>
            <li>
              指示が5文字以内だと追加で2点、10文字以内だと追加で1点獲得します。
            </li>
            <li>10秒以内に回答された場合、追加で1点獲得します。</li>
          </ul>
        </li>
        <li>時間内にAIに指示をあたえられなかった場合、2点失います。</li>
      </ul>
      <Text>プレイヤー</Text>
      <ul>
        <li>お題を当てると、3点獲得します。</li>
        <li>
          さらに、このとき:
          <ul>
            <li>10秒以内に回答した場合、追加で2点獲得します。</li>
            <li>20秒以内に回答した場合、追加で1点獲得します。</li>
            <li>
              残り時間が10秒を切ったときに回答した場合、追加で5点獲得します。
            </li>
          </ul>
        </li>
      </ul>
    </Root>
  );
};

const Root = styled.div`
  padding: var(--space-m);

  h2 {
    margin-top: 0;
    margin-bottom: var(--space-m);
    text-align: center;
  }
  h3 {
    margin: var(--space-l) 0;
    text-align: center;
    font-size: var(--font-size-l);
  }
  li {
    font-size: var(--font-size-m);
    margin-bottom: var(--space-m);
  }
`;
