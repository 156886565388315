import { initializeApp } from "firebase/app";
import {
  connectFirestoreEmulator,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { connectStorageEmulator, getStorage } from "firebase/storage";

const app = initializeApp({
  apiKey: "AIzaSyDaMqxuHSWa8-oCDYFwyxMdthoMrvIEaAo",
  authDomain: "ai-oekaki.jp",
  projectId: "oekaki-dengon-game",
  storageBucket: "oekaki-dengon-game.appspot.com",
  messagingSenderId: "211148702649",
  appId: "1:211148702649:web:124fe97b7a2a36e505ec68",
  measurementId: "G-3MZ2V431HB",
});
export const db = getFirestore(app);
export const auth = getAuth(app);

export const functions = getFunctions(app);

export const analytics = getAnalytics(app);

export const storage = getStorage(app);

if (process.env.NODE_ENV === "development") {
  const host = window.location.hostname;
  connectAuthEmulator(auth, `http://${host}:9099`);
  connectFirestoreEmulator(db, host, 8080);
  connectFunctionsEmulator(functions, host, 5001);
  connectStorageEmulator(storage, host, 9199);

  setTimeout(() => {
    setDoc(doc(db, "themes/hoge"), {
      name: "ポケモン 151匹",
      subjects:
        "ピカチュウ、カイリュー、ヤドラン、ピジョン、コダック、コラッタ、ズバット、ギャロップ、サンダース、メノクラゲ、パウアウ、カラカラ、タマタマ、ガラガラ、フシギダネ"
          .split("、")
          .map((c) => ({ name: c, answer: null })),
      weight: 0,
    });
  }, 3000);
}
