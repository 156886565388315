import { FC, useState } from "react";
import styled from "styled-components";
import { useUser } from "../hooks/userUser";
import { useUserProfile } from "../hooks/useUserProfile";
import { useAccount } from "../hooks/useAccount";
import { LoginDialog } from "./LoginDialog";
import { Dialog } from "../element/Dialog";
import { Button } from "../element/Button";
import { Text } from "../element/Text";
import { signOut } from "firebase/auth";
import { auth } from "../firebase/config";

export const AccountView: FC = () => {
  const user = useUser();
  const account = useAccount(user);
  const [userProfile] = useUserProfile();

  const [isLoginDialogVisible, setLoginVisible] = useState(false);
  const [isAccountDialogVisible, setAccountDialogVisible] = useState(false);

  if (account == null)
    return (
      <Root>
        <Tappable onClick={() => setLoginVisible(true)}>ログインする</Tappable>
        {isLoginDialogVisible && (
          <LoginDialog text="" onClose={() => setLoginVisible(false)} />
        )}
      </Root>
    );
  return (
    <Root>
      <Tappable onClick={() => setAccountDialogVisible(true)}>
        <div>{userProfile.name !== "" ? userProfile.name : "匿名"}さん</div>
        <div>所持チケット {account.tickets}枚</div>
      </Tappable>
      {isAccountDialogVisible && (
        <Dialog onClose={() => setAccountDialogVisible(false)}>
          <DialogWrapper>
            <Text>プレイヤー名 {userProfile.name}</Text>
            <Text>所持チケット {account.tickets}枚</Text>
            <Hint>
              チケットが3枚より不足している場合、 定期的に付与されます
            </Hint>
            <Button
              onClick={() => {
                signOut(auth);
                setAccountDialogVisible(false);
              }}
            >
              ログアウトする
            </Button>
            <Button onClick={() => setAccountDialogVisible(false)}>
              閉じる
            </Button>
          </DialogWrapper>
        </Dialog>
      )}
    </Root>
  );
};

const Root = styled.div`
  position: fixed;
  top: var(--space-s);
  right: 0;
  background-color: #00000088;
  padding: var(--space-s);
`;

const Tappable = styled.button`
  outline: none;
  border: none;
  background: transparent;
  min-width: 0;
  min-height: 0;
  color: #fff;
  font-size: var(--font-size-xs);
  text-align: left;
`;

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
`;

const Hint = styled.div`
  font-size: var(--font-size-xs);
  text-align: center;
`;
