import { useEffect, useState } from "react";
import { accountRepository } from "../repositories/AccountRepository";
import { User } from "firebase/auth";
import { Account } from "../models/Account";

export const useAccount = (user: User) => {
  const [account, setAccount] = useState<Account>();

  useEffect(() => {
    return accountRepository.listen(user, (received) => {
      setAccount(received);
    });
  }, [user]);

  return account;
};
