import { FC } from "react";
import styled from "styled-components";
import { Text } from "./Text";

type Props = {
  items: { text: string; value: string }[];
  value: string;
  onSelect: (value: string) => void;
  isVertical?: boolean;
};

export const RadioButtons: FC<Props> = ({
  items,
  value,
  onSelect,
  isVertical = false,
}) => {
  return (
    <Root $isVertical={isVertical}>
      {items.map((item) => (
        <RadioButton
          key={item.value}
          text={item.text}
          value={item.value}
          isSelected={item.value === value}
          onSelect={onSelect}
        />
      ))}
    </Root>
  );
};

const Root = styled.div<{ $isVertical: boolean }>`
  display: flex;
  flex-direction: ${({ $isVertical }) => ($isVertical ? "column" : "row")};
  align-items: ${({ $isVertical }) => ($isVertical ? "start" : "center")};

  justify-content: space-around;
`;

type RadioButtonProps = {
  text: string;
  value: string;
  isSelected: boolean;
  onSelect: (value: string) => void;
};

const RadioButton: FC<RadioButtonProps> = ({
  text,
  value,
  isSelected,
  onSelect,
}) => {
  return (
    <RadioButtonRoot>
      <input
        type="radio"
        value={value}
        checked={isSelected}
        onChange={(e) => onSelect(value)}
      />
      <Text>{text}</Text>
    </RadioButtonRoot>
  );
};

const RadioButtonRoot = styled.label`
  padding: var(--space-s);
  input {
    display: none;

    & + *:before {
      content: "";
      background: #fff;
      border-radius: 100%;
      border: 2px solid var(--color-border);
      display: inline-block;
      width: 1.4em;
      height: 1.4em;
      position: relative;
      top: -0.1em;
      margin-right: var(--space-xs);
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
    }

    &:checked + *:before {
      background-color: var(--color-primary);
      box-shadow: inset 0 0 0 4px #fff;
    }
  }
`;
