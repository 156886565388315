import {
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db, functions } from "../firebase/config";
import { Game } from "../models/Game";
import { UserProfile } from "../models/UserProfile";
import { Theme } from "../models/Theme";
import { httpsCallable } from "firebase/functions";

const toGame = (id: string, data: any): Game => {
  return {
    id: id,
    players: data.players,
    prompts: data.prompts,
    imagePaths: data.imagePaths,
    state: data.state,
    dealerIndex: data.dealerIndex,
    themeId: data.themeId,
    themeName: data.themeName,
    subjects: data.subjects,
    messages: data.messages,
    timerStartedAt: data.timerStartedAt?.toDate(),
    createdAt: data.createdAt.toDate(),
    host: data.host,
    isPublic: data.isPublic,
  };
};

export const gameRepository = {
  async list(): Promise<Game[]> {
    const q = query(
      collection(db, "games"),
      where("isPublic", "==", true),
      where("state", "==", "waiting"),
      orderBy("createdAt", "desc"),
      limit(20)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return [];

    let games: Game[] = [];
    querySnapshot.docs.forEach((doc) => {
      const data = doc.data();
      const game = toGame(doc.id, data);
      games.push(game);
    });
    return games;
  },

  listen(id: string, listener: (game: Game | undefined) => void): () => void {
    return onSnapshot(doc(db, "games", id), (docSnapshot) => {
      const data = docSnapshot.data();
      if (data == null) {
        listener(undefined);
        return;
      }

      listener(toGame(docSnapshot.id, data));
    });
  },

  async create({
    userProfile,
    theme,
    isPublic,
  }: {
    userProfile: UserProfile;
    theme: Theme;
    isPublic: boolean;
  }): Promise<Game> {
    const result = await httpsCallable(
      functions,
      "game-create"
    )({
      userProfile,
      themeId: theme.id,
      isPublic,
    });
    return result.data as Game;
  },

  async join({ game, userProfile }: { game: Game; userProfile: UserProfile }) {
    await httpsCallable(
      functions,
      "game-join"
    )({
      gameId: game.id,
      userProfile,
    });
  },

  async start(game: Game) {
    await httpsCallable(
      functions,
      "game-start"
    )({
      gameId: game.id,
    });
  },

  async postPrompt(game: Game, prompt: string) {
    await httpsCallable(
      functions,
      "game-postPrompt"
    )({
      gameId: game.id,
      prompt,
    });
  },

  async postAnswer(game: Game, answer: string) {
    await httpsCallable(
      functions,
      "game-postAnswer"
    )({
      gameId: game.id,
      answer,
    });
  },

  async skip(game: Game) {
    await httpsCallable(
      functions,
      "game-skip"
    )({
      gameId: game.id,
    });
  },
};
