import { addDoc, collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase/config";
import { Theme } from "../models/Theme";

export const themeRepository = {
  async initialize() {
    const subjects = [
      {
        name: "あ",
        answer: null,
      },
      {
        name: "い",
        answer: null,
      },
      {
        name: "う",
        answer: null,
      },
      {
        name: "え",
        answer: null,
      },
      {
        name: "お",
        answer: null,
      },
      {
        name: "か",
        answer: null,
      },
      {
        name: "き",
        answer: null,
      },
      {
        name: "く",
        answer: null,
      },
    ];
    await addDoc(collection(db, "themes"), {
      name: "ことわざ（ひらがな）",
      subjects: subjects,
      weight: 0,
    });
  },

  async findAll(): Promise<Theme[]> {
    const q = query(collection(db, "themes"));
    const querySnapshot = await getDocs(q);

    let themes: Theme[] = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      themes.push({
        id: doc.id,
        name: data.name,
        subjects: data.subjects,
        weight: data.weight,
      });
    });
    return themes.sort((a, b) => a.weight - b.weight);
  },
};
