import { FC } from "react";
import styled from "styled-components";

type Props = {
  message?: string;
};

export const FullscreenLoading: FC<Props> = ({
  message = "データを読み込んでいます",
}) => {
  return (
    <Root>
      <Wrapper>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <Message>{message}</Message>
      </Wrapper>
    </Root>
  );
};

const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--font-size-m);
  font-weight: bold;
  width: max-content;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    border: 4px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ccc transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Message = styled.div`
  color: #666;
  flex-grow: 1;
`;
