import { FC, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { gameRepository } from "../repositories/GameRepository";
import { Game } from "../models/Game";
import { FullscreenLoading } from "../components/FullscreenLoading";
import styled from "styled-components";
import { Button } from "../element/Button";
import { LabelAndValue } from "../components/LabelAndValue";
import { RankingView } from "../components/gameResult/RankingView";
import { AllImagesView } from "../components/gameResult/AllImagesView";
import { AdContainer } from "../components/AdContainer";

export const GameResult: FC = () => {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const [game, setGame] = useState<Game>();

  useEffect(() => {
    if (gameId == null) return;
    const unsubscribe = gameRepository.listen(gameId, (newGame) => {
      if (newGame == null) return;

      if (
        ["waiting", "prompting", "thinking", "generating"].includes(
          newGame.state
        )
      ) {
        navigate(`/rooms/${gameId}`);
        return;
      }

      setGame(newGame);
      unsubscribe();
    });
    return () => unsubscribe();
  }, [gameId, setGame]);

  if (game == null) return <FullscreenLoading />;
  return (
    <Root>
      <Heading>結果</Heading>
      <LabelAndValue label="テーマ" value={game.themeName} />
      <RankingView players={game.players} />
      <AllImagesView game={game} />
      <HomeLink to="/">
        <HomeButton>ホームへ戻る</HomeButton>
      </HomeLink>
    </Root>
  );
};

const Root = styled(AdContainer)`
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Heading = styled.h1`
  text-align: center;
  margin: 0;
`;

const HomeLink = styled(Link)`
  align-self: center;
`;

const HomeButton = styled(Button)`
  width: auto;
`;
