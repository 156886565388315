import { useEffect } from "react";

export const useWakeLock = () => {
  useEffect(() => {
    if (!("wakeLock" in navigator)) return;

    let wakeLock: any = null;
    try {
      // @ts-ignore
      navigator.wakeLock.request("screen").then((w) => (wakeLock = w));
    } catch (e) {}
    return () => {
      wakeLock?.release().then(() => {
        wakeLock = null;
      });
    };
  }, []);
};
