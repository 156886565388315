import { FC } from "react";
import styled from "styled-components";

export const TitleView: FC = () => {
  return <Root>AIでお絵描きゲーム</Root>;
};

const Root = styled.h1`
  text-align: center;
  margin: 0;

  font-family: sans-serif;
  font-weight: bold;
  font-size: var(--font-size-xxl);
  color: var(--color-primary);
  text-shadow: -2px -2px 1px #000, 2px -2px 1px #000, -2px 2px 1px #000,
    2px 2px 1px #000;
`;
