import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Game } from "../models/Game";
import { Text } from "../element/Text";
import { gameRepository } from "../repositories/GameRepository";
import { RoomItemView } from "../components/rooms/RoomItemView";
import { AdContainer } from "../components/AdContainer";
import { Button } from "../element/Button";
import { useUser } from "../hooks/userUser";
import { LoginDialog } from "../components/LoginDialog";
import { RoomCreationDialog } from "../components/rooms/RoomCreationDialog";
import { Theme } from "../models/Theme";
import { useUserProfile } from "../hooks/useUserProfile";
import { useNavigate } from "react-router-dom";
import { LoadingDialog } from "../components/LoadingDialog";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { MultPlayerGameRuleDialog } from "../components/rooms/MultPlayerGameRuleDialog";
import { useAdHeight } from "../hooks/useAdHeight";
import { useAccount } from "../hooks/useAccount";
import { NoTicketsDialog } from "../components/rooms/NoTicketsDialog";
import { AccountView } from "../components/AccountView";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase/config";

export const Rooms: FC = () => {
  const user = useUser();
  const account = useAccount(user);
  const [userProfile, updateUserProfile] = useUserProfile();

  const navigate = useNavigate();
  const adHeight = useAdHeight();
  const [games, setGames] = useState<Game[]>([]);

  const [isLoginDialogVisible, setLoginDialogVisible] = useState(false);
  const [isRoomCreationDialogVisible, setRoomCreationDialogVisible] =
    useState(false);
  const [isRoomCreatingDialogVisible, setRoomCreatingDialogVisible] =
    useState(false);
  const [isRuleDialogVisible, setRuleDialogVisible] = useState(false);
  const [isNoTicketsDialogVisible, setNoTicketsDialogVisible] = useState(false);

  useEffect(() => {
    gameRepository.list().then((games) => setGames(games));
  }, []);

  const onRoomCreationButtonClick = () => {
    if (user.isAnonymous) {
      setLoginDialogVisible(true);
      return;
    }
    if (account == null || account.tickets < 1) {
      setNoTicketsDialogVisible(true);
      return;
    }
    setRoomCreationDialogVisible(true);
  };

  const onRoomCreationSubmit = async ({
    theme,
    playerName,
    isPublic,
  }: {
    theme: Theme;
    playerName: string;
    isPublic: boolean;
  }) => {
    setRoomCreatingDialogVisible(true);

    const targetUserProfile =
      playerName === userProfile.name
        ? userProfile
        : await updateUserProfile({ name: playerName });
    const game = await gameRepository.create({
      theme,
      userProfile: targetUserProfile,
      isPublic,
    });

    logEvent(analytics, "create_room", {
      game_id: game.id,
      theme_id: theme.id,
      theme_name: theme.name,
    });
    navigate(`/rooms/${game.id}`);
  };

  return (
    <Root>
      <AccountView />
      <Content>
        <Breadcrumbs pages={[{ name: "部屋一覧" }]} />
        <h2>部屋一覧</h2>
        {games.length > 0 ? (
          <Text>現在募集中の部屋です。</Text>
        ) : (
          <Text>現在募集中の部屋はありません。</Text>
        )}
        <RoomList>
          {games.map((game) => (
            <RoomItemView key={game.id} game={game} />
          ))}
        </RoomList>
      </Content>
      <Buttons $adHeight={adHeight}>
        <Button onClick={() => setRuleDialogVisible(true)}>遊び方</Button>
        <Button onClick={onRoomCreationButtonClick}>部屋をつくる</Button>
      </Buttons>
      {isLoginDialogVisible && (
        <LoginDialog
          text="ログインをして、部屋をつくりましょう！"
          onClose={() => setLoginDialogVisible(false)}
        />
      )}
      {isNoTicketsDialogVisible && (
        <NoTicketsDialog onClose={() => setNoTicketsDialogVisible(false)} />
      )}
      {isRoomCreationDialogVisible && (
        <RoomCreationDialog
          onSubmit={onRoomCreationSubmit}
          onCancel={() => setRoomCreationDialogVisible(false)}
        />
      )}
      <LoadingDialog
        isVisible={isRoomCreatingDialogVisible}
        message="部屋を作成中です"
      />
      {isRuleDialogVisible && (
        <MultPlayerGameRuleDialog onClose={() => setRuleDialogVisible(false)} />
      )}
    </Root>
  );
};

const Root = styled(AdContainer)`
  position: relative;
  overflow: auto;
  padding-left: 0;
  padding-right: 0;

  h2 {
    margin: 0;
  }
`;

const Content = styled.div`
  padding: 0 var(--space-l);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-m);
`;

const RoomList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  padding: 0 var(--space-l);
  width: 100%;
`;

const Buttons = styled.div<{ $adHeight: number }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: ${({ $adHeight }) => $adHeight}px;
  display: flex;
  background-image: url("/bg.png");
  padding: var(--space-m);
  gap: var(--space-s);

  ${Button} {
    width: auto;
    flex-grow: 1;
  }
`;
