import { FC, useEffect, useState } from "react";
import styled from "styled-components";

type Props = {
  value: string;
};

export const RobotMessage: FC<Props> = ({ value }) => {
  const [visibleText, setVisibleText] = useState("");
  useEffect(() => {
    if (visibleText.length === value.length) return;
    window.setTimeout(() => {
      setVisibleText((prev) => prev.concat(value.at(prev.length) ?? ""));
    }, 100);
  }, [value, visibleText]);
  return <Root>{visibleText}</Root>;
};

const Root = styled.div`
  font-family: "DotGothic16", sans-serif;
  font-size: var(--font-size-l);
  flex-grow: 1;
`;
