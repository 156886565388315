import styled from "styled-components";

export const Button = styled.button`
  outline: none;
  border-radius: var(--space-l);
  border: 2px solid var(--color-border);
  font-size: var(--font-size-l);
  font-weight: bold;
  padding: var(--space-s) var(--space-l);
  text-align: center;
  width: 100%;
  min-width: 4.5rem;
  background-color: var(--color-primary-light);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  &:active {
    background-color: var(--color-primary);
  }
  &:disabled {
    background-color: var(--color-border-disabled);
    color: var(--color-border);
  }
`;
