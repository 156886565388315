import { FC, useEffect } from "react";
import styled from "styled-components";
import { User } from "firebase/auth";
import { GameMessageView } from "./GameMessageView";
import { GameInput } from "./GameInput";
import { Game } from "../../models/Game";

const onResize = () => {
  const height = visualViewport?.height;
  if (height == null) return;
  document.documentElement.style.setProperty(
    "--visual-viewport-height",
    `${height}px`
  );
};

const onScroll = () => {
  const offset =
    visualViewport == null ||
    Math.abs(visualViewport.height - window.innerHeight) < 1
      ? 0
      : visualViewport.offsetTop;
  document.documentElement.style.setProperty("--offset", `${offset}px`);
};

type Props = {
  user: User;
  game: Game;
  onSubmit: (text: string) => void;
};

export const GameFooter: FC<Props> = ({ user, game, onSubmit }) => {
  useEffect(() => {
    visualViewport?.addEventListener("resize", onResize);
    onResize();

    return () => {
      visualViewport?.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    visualViewport?.addEventListener("scroll", onScroll);
    onScroll();
    return () => visualViewport?.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Root>
      <GameMessageView messages={game.messages} />
      <GameInput user={user} game={game} onSubmit={onSubmit} />
    </Root>
  );
};

const Root = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: calc(var(--visual-viewport-height) + var(--offset));
  transform: translateY(-100%);
`;
