import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { FullscreenLoading } from "../FullscreenLoading";
import { imageRepository } from "../../repositories/ImageRepository";
import { httpsCallable } from "firebase/functions";
import { analytics, functions } from "../../firebase/config";
import { useUserProfile } from "../../hooks/useUserProfile";
import { logEvent } from "firebase/analytics";

type Props = {
  prompt: string;
  onResult: (isSuccessful: boolean) => void;
};

export const ImageView: FC<Props> = ({ prompt, onResult }) => {
  const [, updateUserProfile] = useUserProfile();
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    httpsCallable(
      functions,
      "tutorial-generateImage"
    )({
      prompt,
    }).then((response) => {
      const path = response.data as string;
      if (path == null) {
        onResult(false);
        return;
      }

      imageRepository.getImageUrlByPath(path).then((url) => {
        updateUserProfile({ tutorialFinishedAt: new Date() });
        setImageUrl(url);
        onResult(true);
        logEvent(analytics, "tutorial_generate_image", {
          prompt,
          image_path: path,
        });
      });
    });
  }, [prompt]);

  return (
    <Root>
      <img width="512" height="512" src={imageUrl} />
      {imageUrl == null && <FullscreenLoading message="AIが絵を描いています" />}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  border: 2px solid #666;
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
`;
