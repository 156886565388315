import { FC, useState } from "react";
import { Theme } from "../../models/Theme";
import { LabelAndValue } from "../LabelAndValue";
import { TextField } from "../../element/TextField";
import styled from "styled-components";
import { Button } from "../../element/Button";
import { Text } from "../../element/Text";

type Props = {
  theme: Theme;
  subjectIndex: number;
  onBack: () => void;
  onNext: (prompt: string) => void;
};

export const PromptingView: FC<Props> = ({
  theme,
  subjectIndex,
  onBack,
  onNext,
}) => {
  const [prompt, setPrompt] = useState("");

  return (
    <>
      <LabelAndValue label="テーマ" value={theme.name} />
      <LabelAndValue label="お題" value={theme.subjects[subjectIndex].name} />
      <Text>
        テーマにもとづきお題が決定されました。お題の画像が生成されるように、AIに対する指示文を入力してください。
      </Text>
      <Example>
        <div>【例題】テーマ「都道府県」お題「東京都」</div>
        <div>【入力例】赤い電波塔、摩天楼</div>
      </Example>
      <InputArea>
        <TextField
          placeholder="画像生成の指示文"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
        />
        <Buttons>
          <Button onClick={onBack}>もどる</Button>
          <Button
            onClick={() => onNext(prompt)}
            disabled={prompt.trim() === ""}
          >
            次へ
          </Button>
        </Buttons>
      </InputArea>
    </>
  );
};

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  margin-top: var(--space-l);
`;

const Example = styled.div`
  font-size: var(--font-size-xs);
  background: #fff;
  padding: var(--space-xs);
  border-radius: var(--radius-xs);
`;

const Buttons = styled.div`
  display: flex;
  gap: var(--space-s);
`;
