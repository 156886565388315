import { FC, useEffect, useState } from "react";
import { Dialog } from "../../element/Dialog";
import { WelcomeView } from "./WelcomeView";
import { Theme } from "../../models/Theme";
import styled from "styled-components";
import { PromptingView } from "./PromptingView";
import { ResultView } from "./ResultView";
import { useUserProfile } from "../../hooks/useUserProfile";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase/config";

export const TutorialDialog: FC = () => {
  const [userProfile, updateUserProfile] = useUserProfile();
  const [theme, setTheme] = useState<Theme>();
  const [subjectIndex, setSubjectIndex] = useState(0);
  const [prompt, setPrompt] = useState("");
  const [isFinished, setFinished] = useState(
    userProfile.tutorialFinishedAt != null
  );

  useEffect(() => {
    logEvent(analytics, "tutorial_begin");
  }, []);

  const onWelcomeNext = (theme: Theme) => {
    setTheme(theme);
    setSubjectIndex(Math.round((theme.subjects.length - 1) * Math.random()));
  };

  const onPromptingBack = () => {
    setTheme(undefined);
  };

  const onPromptingNext = (prompt: string) => {
    setPrompt(prompt);
  };

  const onResultBack = () => {
    setPrompt("");
  };

  const onSkip = () => {
    updateUserProfile({ tutorialFinishedAt: new Date() });
    setFinished(true);
    logEvent(analytics, "tutorial_skip");
  };

  const onFinish = () => {
    setFinished(true);
    logEvent(analytics, "tutorial_complete");
  };

  if (isFinished) return null;
  return (
    <Dialog>
      <Wrapper>
        {theme == null && (
          <WelcomeView onNext={onWelcomeNext} onSkip={onSkip} />
        )}
        {theme != null && prompt === "" && (
          <PromptingView
            theme={theme}
            subjectIndex={subjectIndex}
            onBack={onPromptingBack}
            onNext={onPromptingNext}
          />
        )}
        {theme != null && prompt !== "" && (
          <ResultView
            theme={theme}
            subjectIndex={subjectIndex}
            prompt={prompt}
            onBack={onResultBack}
            onSkip={onSkip}
            onFinish={onFinish}
          />
        )}
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);

  h2 {
    margin: 0;
    text-align: center;
  }
`;
