import { Game } from "../../models/Game";
import { User } from "firebase/auth";
import { FC } from "react";
import styled from "styled-components";
import { Player } from "../../models/Player";

type Props = {
  user: User;
  game: Game;
};

export const PlayersView: FC<Props> = ({ user, game }) => {
  return (
    <Root>
      {game.players.map((player) => (
        <PlayerView
          key={player.userProfile.id + player.userProfile.name}
          player={player}
          user={user}
          game={game}
        />
      ))}
    </Root>
  );
};

const PlayerView: FC<Props & { player: Player }> = ({ player, user, game }) => {
  const isDealer =
    player.userProfile.userId ===
    game.players[game.dealerIndex].userProfile.userId;
  return (
    <PlayerViewRoot>
      <Name $isDealer={isDealer}>
        {player.userProfile.name}
        {isDealer && <Dealer>画伯</Dealer>}
      </Name>
      <ScoreView>
        <Score>{player.score}</Score>
        <ScoreUnit>点</ScoreUnit>
      </ScoreView>
    </PlayerViewRoot>
  );
};

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--space-m);
`;

const PlayerViewRoot = styled.div`
  border: 2px solid var(--color-border);
  border-radius: var(--radius-xs);
  display: flex;
`;

const Name = styled.div<{ $isDealer: boolean }>`
  display: flex;
  align-items: end;
  font-size: var(--font-size-s);
  font-weight: bold;
  flex-grow: 1;
  padding: var(--space-s);
  background-color: ${({ $isDealer }) =>
    $isDealer ? "var(--color-primary-light)" : "#fff"};
`;

const Dealer = styled.div`
  font-size: var(--font-size-xs);
  font-weight: normal;
`;

const ScoreView = styled.div`
  display: flex;
  align-items: end;
  border-left: 2px solid var(--color-border);
  padding: var(--space-s);
  background-color: #fff;
`;

const Score = styled.div`
  font-size: var(--font-size-l);
  font-weight: bold;
`;

const ScoreUnit = styled.div`
  font-size: var(--font-size-xs);
`;
