import { User } from "firebase/auth";
import { useAtom } from "jotai/index";
import { userProfileAtom } from "../atom/userProfileAtom";
import { useEffect } from "react";
import { UserProfile } from "../models/UserProfile";
import { userProfileRepository } from "../repositories/UserProfileRepository";

export const useUserProfileInitialization = (
  user: User | "loading"
): UserProfile | "loading" => {
  const [userProfile, setUserProfile] = useAtom(userProfileAtom);

  useEffect(() => {
    if (user === "loading") return;

    userProfileRepository.findByUser(user).then((existing) => {
      if (existing != null) {
        setUserProfile(existing);
        return;
      }

      userProfileRepository
        .create({
          userId: user.uid,
          name: "",
          tutorialFinishedAt: null
        })
        .then((created) => {
          setUserProfile(created);
        });
    });
  }, [user]);

  return userProfile;
};
