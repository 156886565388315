import { FC } from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  onClose?: () => void;
};

export const Dialog: FC<Props> = ({ children, onClose }) => {
  return (
    <Root onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()}>{children}</Wrapper>
    </Root>
  );
};

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
`;

const Wrapper = styled.div`
  width: 75%;
  background-image: url("/bg.png");
  background-repeat: repeat;
  border-radius: 8px;
  border: 2px solid var(--color-border);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 32px #000;
  padding: var(--space-l);
  max-height: 75%;
  overflow: auto;
`;
