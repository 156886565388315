import { FC } from "react";
import { Dialog } from "../../element/Dialog";
import { Text } from "../../element/Text";
import { Button } from "../../element/Button";
import styled from "styled-components";

type Props = {
  onClose: () => void;
};

export const NoTicketsDialog: FC<Props> = ({ onClose }) => {
  return (
    <Dialog onClose={onClose}>
      <Wrapper>
        <Text>チケットが足りないようです。</Text>
        <Hint>チケットが3枚より不足している場合、 定期的に付与されます</Hint>
        <Button onClick={onClose}>閉じる</Button>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-m);
`;

const Hint = styled.div`
  font-size: var(--font-size-xs);
  text-align: center;
`;
