import { Game } from "../../models/Game";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { imageRepository } from "../../repositories/ImageRepository";
import { Text } from "../../element/Text";
import { SmallText } from "../../element/SmallText";
import { ImageDialog } from "./ImageDialog";

type Props = {
  game: Game;
};

export const AllImagesView: FC<Props> = ({ game }) => {
  const [imageUrls, setImageUrls] = useState([] as string[]);

  const [selectedImage, setSelectedImage] = useState<{
    subject: string;
    playerName: string;
    prompt: string;
    imageUrl: string;
  }>();

  useEffect(() => {
    imageRepository.getAllImageUrls(game).then((urls) => setImageUrls(urls));
  }, []);

  return (
    <Root>
      <SmallText>作品をタップすると、AIへの指示がわかるよ</SmallText>
      <Grid>
        {imageUrls.map((imageUrl, index) => {
          const subject = game.subjects[index].name;
          const playerName = game.players[index].userProfile.name;
          return (
            <ImageView
              key={imageUrl + index}
              onClick={() => {
                if (imageUrl === "") return;
                setSelectedImage({
                  subject,
                  playerName,
                  imageUrl,
                  prompt: game.prompts[index],
                });
              }}
            >
              {imageUrl === "" ? (
                <Empty />
              ) : (
                <img src={imageUrl} alt={subject} />
              )}
              <Text>「{subject}」</Text>
              <Text>{playerName}</Text>
            </ImageView>
          );
        })}
      </Grid>
      {selectedImage != null && (
        <ImageDialog
          {...selectedImage}
          onClose={() => setSelectedImage(undefined)}
        />
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-s);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

const ImageView = styled.button`
  outline: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px;
  background-color: #fff;
  border: 2px solid #666;

  img {
    width: 100%;
  }
`;

const Empty = styled.div`
  width: 100%;
  height: 100%;
`;
