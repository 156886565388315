import { FC, useEffect, useMemo, useState } from "react";
import { Game } from "../../models/Game";
import styled from "styled-components";
import QRCode from "qrcode";
import { TextField } from "../../element/TextField";
import { LabelAndValue } from "../../components/LabelAndValue";
import { Text } from "../../element/Text";
import { SmallButton } from "../../element/SmallButton";
import { Button } from "../../element/Button";
import { LoadingDialog } from "../../components/LoadingDialog";
import { gameRepository } from "../../repositories/GameRepository";
import { AdContainer } from "../AdContainer";
import { MultiPlayerGameRule } from "../MultiPlayerGameRule";
import { Breadcrumbs } from "../Breadcrumbs";
import { useUserProfile } from "../../hooks/useUserProfile";
import { useUser } from "../../hooks/userUser";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase/config";

type Props = {
  game: Game;
};

const MAX_PLAYER_COUNT = 8;

export const Waiting: FC<Props> = ({ game }) => {
  const user = useUser();
  const [userProfile, updateUserProfile] = useUserProfile();
  const [qrCodeBase64, setQrCodeBase64] = useState<string>();
  const isHost = useMemo(() => {
    return game.players[0].userProfile.userId === user.uid;
  }, [user, game]);
  const isJoined = useMemo(() => {
    if (user == null || game == null) return false;
    return (
      game.players.find((player) => player.userProfile.userId === user.uid) !=
      null
    );
  }, [user, game]);

  const [playerName, setPlayerName] = useState(userProfile.name);
  const [isJoiningDialogVisible, setJoiningDialogVisible] = useState(false);
  const [isStartingGameDialogVisible, setStartingGameDialogVisible] =
    useState(false);

  // 初期状態でのプレイヤー人数
  const initialPlayersNumber = useMemo(() => game.players.length, []);

  useEffect(() => {
    QRCode.toDataURL(window.location.href, { width: 200 }, (_, url) => {
      setQrCodeBase64(url);
    });
  }, []);

  const onJoinButtonClick = async () => {
    if (game == null) return;
    setJoiningDialogVisible(true);
    const newUserProfile =
      playerName === userProfile.name
        ? userProfile
        : await updateUserProfile({ name: playerName });
    await gameRepository.join({ game, userProfile: newUserProfile });
    setJoiningDialogVisible(false);
    logEvent(analytics, "join_room", {
      game_id: game.id,
      theme_id: game.themeId,
      theme_name: game.themeName,
    });
  };

  const onStartButtonClick = async () => {
    setStartingGameDialogVisible(true);
    await gameRepository.start(game);
    logEvent(analytics, "start_game", {
      game_id: game.id,
      theme_id: game.themeId,
      theme_name: game.themeName,
      players_count: game.players.length,
    });
  };

  return (
    <Root>
      <Breadcrumbs
        pages={[
          { name: "部屋一覧", path: "/rooms" },
          { name: `${game.isPublic ? "" : "非"}公開部屋` },
        ]}
      />
      <LabelAndValue label="テーマ" value={game.themeName} />
      <Separator />
      <Text style={{ textAlign: "center" }}>
        参加プレイヤー（{game.players.length}/{MAX_PLAYER_COUNT}）
      </Text>
      <Players>
        {game.players.map((player, index) => (
          <PlayerName
            key={player.userProfile.id}
            $isAnimationEnabled={initialPlayersNumber < index + 1}
          >
            {player.userProfile.name}
          </PlayerName>
        ))}
      </Players>
      <Separator />
      {isJoined &&
        (isHost ? (
          <>
            <Text style={{ textAlign: "center" }}>プレイヤーが揃ったら</Text>
            <StartButton
              onClick={onStartButtonClick}
              disabled={game.players.length < 2}
            >
              ゲームスタート！
            </StartButton>
          </>
        ) : (
          <WaitingForStart>
            <Text>ゲームの開始をお待ちください</Text>
          </WaitingForStart>
        ))}
      {!isJoined && game.players.length < MAX_PLAYER_COUNT && (
        <>
          <Text>プレイヤー名を入力して参加してください</Text>
          <PlayerNameInputArea>
            <TextField
              value={playerName}
              onChange={(e) => setPlayerName(e.target.value)}
            />
            <SmallButton onClick={onJoinButtonClick}>参加</SmallButton>
          </PlayerNameInputArea>
        </>
      )}
      <GameSharingArea>
        <GameSharingAreaRow>
          <Text>URLまたはQRコードを他のプレイヤーに共有してください</Text>
          {qrCodeBase64 != null && <img src={qrCodeBase64} />}
        </GameSharingAreaRow>
        <TextField defaultValue={window.location.href} disabled />
      </GameSharingArea>
      <RuleArea>
        <MultiPlayerGameRule />
      </RuleArea>
      <LoadingDialog
        isVisible={isJoiningDialogVisible}
        message="参加しています"
      />
      <LoadingDialog
        isVisible={isStartingGameDialogVisible}
        message="まもなくスタートします"
      />
    </Root>
  );
};

const Root = styled(AdContainer)`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
`;

const Separator = styled.div`
  height: var(--space-m);
`;

const StartButton = styled(Button)`
  width: fit-content;
  align-self: center;
`;

const PlayerNameInputArea = styled.div`
  display: flex;
  gap: var(--space-s);
  input {
    flex-grow: 1;
  }
`;

const Players = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
  align-items: center;
`;

const PlayerName = styled.div<{ $isAnimationEnabled: boolean }>`
  border: 2px solid var(--color-border);
  border-radius: var(--radius-s);
  padding: var(--space-s);
  width: 100%;
  background-color: var(--color-primary);

  text-align: center;
  font-size: var(--font-size-l);
  font-weight: bold;
  font-style: italic;

  animation: ${({ $isAnimationEnabled }) =>
    $isAnimationEnabled ? "in 500ms ease-out" : "none"};
  @keyframes in {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

const GameSharingArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);

  margin-top: var(--space-l);
  padding: var(--space-m);
  border: 2px solid var(--color-border);
  border-radius: var(--radius-m);
`;

const GameSharingAreaRow = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-m);

  img {
    width: 100px;
    align-self: center;
  }
`;

const RuleArea = styled.div`
  border: 2px solid var(--color-border);
  border-radius: var(--radius-m);
  margin-top: var(--space-m);
`;

const WaitingForStart = styled.div`
  display: flex;
  justify-content: center;
`;
