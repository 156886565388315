import { Dialog } from "../../element/Dialog";
import { FC, useEffect, useState } from "react";
import { Select } from "../../element/Select";
import styled from "styled-components";
import { Theme } from "../../models/Theme";
import { FullscreenLoading } from "../FullscreenLoading";
import { Button } from "../../element/Button";
import { TextField } from "../../element/TextField";
import { Text } from "../../element/Text";
import { RadioButtons } from "../../element/RadioButtons";
import { useUserProfile } from "../../hooks/useUserProfile";
import { useThemes } from "../../hooks/useThemes";

type Props = {
  onSubmit: ({
    theme,
    playerName,
    isPublic,
  }: {
    theme: Theme;
    playerName: string;
    isPublic: boolean;
  }) => void;
  onCancel: () => void;
};

export const RoomCreationDialog: FC<Props> = ({ onSubmit, onCancel }) => {
  const themes = useThemes();

  const [userProfile] = useUserProfile();
  const [selectedThemeId, setSelectedThemeId] = useState("");
  const [isPublic, setPublic] = useState(true);
  const [playerName, setPlayerName] = useState(userProfile.name ?? "");

  useEffect(() => {
    if (themes.length === 0) return;
    setSelectedThemeId(themes[0].id);
  }, [themes]);

  const onSubmitButtonClick = () => {
    const theme = themes.find((t) => t.id === selectedThemeId);
    if (theme == null) return;

    onSubmit({ theme, playerName, isPublic });
  };

  return (
    <Dialog onClose={onCancel}>
      <Wrapper>
        <h2>部屋をつくる</h2>
        {themes.length === 0 ? (
          <FullscreenLoading />
        ) : (
          <>
            <Area>
              <Text>お題のテーマを選んでください</Text>
              <Select
                value={selectedThemeId}
                onChange={(id) => setSelectedThemeId(id)}
              >
                {themes.map((theme) => (
                  <option key={theme.id} value={theme.id}>
                    {theme.name}
                  </option>
                ))}
              </Select>
            </Area>
            <Area>
              <Text>部屋を公開しますか？</Text>
              <RadioButtons
                items={[
                  { text: "【公開】一覧に表示させる", value: "public" },
                  { text: "【非公開】表示させない", value: "private" },
                ]}
                isVertical={true}
                value={isPublic ? "public" : "private"}
                onSelect={(value) => setPublic(value === "public")}
              />
            </Area>
            <Area>
              <Text>あなたの名前を入力してください</Text>
              <TextField
                type="text"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
                maxLength={20}
              />
            </Area>
            <div>
              <Notice>チケットを1枚消費して部屋をつくります</Notice>
              <Hint>作成した部屋は、一定期間後に削除されます</Hint>
            </div>
            <Buttons>
              <Button onClick={onCancel}>やめる</Button>
              <Button
                onClick={onSubmitButtonClick}
                disabled={playerName.trim() === ""}
              >
                決定
              </Button>
            </Buttons>
          </>
        )}
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);

  h2 {
    margin: 0;
    text-align: center;
  }
`;

const Area = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-s);
`;

const Buttons = styled.div`
  display: flex;
  gap: var(--space-m);
`;

const Hint = styled.div`
  font-size: var(--font-size-xs);
  font-weight: bold;
  text-align: center;
`;

const Notice = styled.div`
  font-size: var(--font-size-xs);
  font-weight: bold;
  color: red;
  text-align: center;
`;
