import { useEffect, useState } from "react";

export const useAdHeight = (): number => {
  const [adHeight, setAdHeight] = useState(0);
  useEffect(() => {
    const target = document.getElementById("nend_adspace_408831_1158729");
    if (target == null) return;

    if (target.clientHeight > 0) {
      setAdHeight(target.clientHeight);
      return;
    }

    const observer = new MutationObserver((mutations, observer) => {
      setAdHeight(target.clientHeight);
    });

    observer.observe(target, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, []);
  return adHeight;
};
