import { UserProfile } from "../models/UserProfile";
import { userProfileAtom } from "../atom/userProfileAtom";
import { useAtom } from "jotai";
import { userProfileRepository } from "../repositories/UserProfileRepository";

export const useUserProfile = (): [
  UserProfile,
  (userProfile: Partial<UserProfile>) => Promise<UserProfile>
] => {
  const [userProfile, setUserProfile] = useAtom(userProfileAtom);
  if (userProfile === "loading") throw new Error("ありえない");

  const update = async (
    userProfilePart: Partial<UserProfile>
  ): Promise<UserProfile> => {
    const updated = await userProfileRepository.update({
      ...userProfile,
      ...userProfilePart,
    });
    setUserProfile(updated);
    return updated;
  };
  return [userProfile, update];
};
