import React, { FC } from "react";
import styled from "styled-components";

type Props = {
  children: React.ReactNode;
  value: string;
  onChange: (value: string) => void;
};

export const Select: FC<Props> = ({ children, value, onChange }) => {
  return (
    <Root>
      <select value={value} onChange={(e) => onChange(e.target.value)}>
        {children}
      </select>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 24px;
    top: 18px;
    width: 10px;
    height: 10px;
    border-top: 2px solid var(--color-border);
    border-left: 2px solid var(--color-border);
    pointer-events: none;
    transform: translateY(-50%) rotate(-135deg);
  }
  select {
    font-size: var(--font-size-l);
    outline: none;
    padding: var(--space-s) var(--space-m);
    border-radius: var(--space-l);
    border: 2px solid var(--color-border);
    position: relative;
    appearance: none;
    width: 100%;
    background: #fff;
  }
`;
