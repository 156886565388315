import { FC, useEffect, useState } from "react";
import { useThemes } from "../../hooks/useThemes";
import { FullscreenLoading } from "../FullscreenLoading";
import { Text } from "../../element/Text";
import { Select } from "../../element/Select";
import { Button } from "../../element/Button";
import { Theme } from "../../models/Theme";
import styled from "styled-components";

type Props = {
  onNext: (theme: Theme) => void;
  onSkip: () => void;
};

export const WelcomeView: FC<Props> = ({ onNext, onSkip }) => {
  const themes = useThemes();
  const [selectedTheme, setSelectedTheme] = useState<Theme>();

  useEffect(() => {
    setSelectedTheme(themes[0]);
  }, [themes]);

  return (
    <>
      {selectedTheme == null ? (
        <FullscreenLoading />
      ) : (
        <>
          <h2>ようこそ</h2>
          <Text>さっそく、画像生成で遊んでみましょう！</Text>
          <Text>お題のテーマを選んでください。</Text>
          <Select
            value={selectedTheme.id}
            onChange={(id) => {
              const theme = themes.find((theme) => theme.id === id);
              setSelectedTheme(theme);
            }}
          >
            {themes.map((theme) => (
              <option key={theme.id} value={theme.id}>
                {theme.name}
              </option>
            ))}
          </Select>
          <Button onClick={() => onNext(selectedTheme!)}>次へ</Button>
          <Skip href="#" onClick={onSkip}>
            スキップする
          </Skip>
        </>
      )}
    </>
  );
};

const Skip = styled.a`
  font-size: var(--font-size-xs);
  text-align: center;
`;
