import { FC, useState } from "react";
import { Theme } from "../../models/Theme";
import { LabelAndValue } from "../LabelAndValue";
import { Dialog } from "../../element/Dialog";
import { Text } from "../../element/Text";
import { ImageView } from "./ImageView";
import styled from "styled-components";
import { Button } from "../../element/Button";

type Props = {
  theme: Theme;
  subjectIndex: number;
  prompt: string;
  onBack: () => void;
  onSkip: () => void;
  onFinish: () => void;
};

export const ResultView: FC<Props> = ({
  theme,
  subjectIndex,
  prompt,
  onBack,
  onSkip,
  onFinish,
}) => {
  const [isSuccessful, setSuccessful] = useState<boolean>();

  return (
    <>
      <LabelAndValue label="テーマ" value={theme.name} />
      <LabelAndValue label="お題" value={theme.subjects[subjectIndex].name} />
      <ImageView prompt={prompt} onResult={setSuccessful} />
      {isSuccessful === true && (
        <>
          <Text
            style={{
              fontWeight: "bold",
              color: "red",
              marginTop: "var(--space-m)",
              textAlign: "center",
            }}
          >
            おめでとうございます！
          </Text>
          <Description>
            画像が生成されました。
            <br />「{theme.subjects[subjectIndex].name}」を上手に描けましたか？
            <br />
            このゲームは、AIで生成した画像のお題を当てたり当てられたりするゲームです。
            <br />
            対人戦で、ぜひ盛り上がってください！
          </Description>
          <Button onClick={onFinish}>閉じる</Button>
        </>
      )}
      {isSuccessful === false && (
        <Dialog>
          <DialogWrapper>
            <h2>おっと</h2>
            <div>
              <Text>問題が起こったようです。</Text>
              <Text>AIが不適切な指示文だと判断したようです。</Text>
              <Text>もう一度、指示文を考えてみてください。</Text>
            </div>
            <Button onClick={onBack}>入力しなおす</Button>
            <a href="#" onClick={onSkip}>
              チュートリアルをスキップする
            </a>
          </DialogWrapper>
        </Dialog>
      )}
    </>
  );
};

const Description = styled.div`
  font-size: var(--font-size-xs);
`;

const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);

  a {
    font-size: var(--font-size-xs);
    text-align: center;
  }
`;
