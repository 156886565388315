import { Game } from "../../models/Game";
import { FC } from "react";
import styled from "styled-components";
import { LabelAndValue } from "../LabelAndValue";
import { Link } from "react-router-dom";

const withZero = (num: number): string => {
  if (num < 10) return `0${num}`;
  return num.toString();
};

const toDateTimeText = (date: Date): string => {
  const diff = (new Date().getTime() - date.getTime()) / 1000;
  if (diff < 60) return "たった今";
  if (diff < 60 * 5) return "5分以内";
  if (diff < 60 * 10) return "10分以内";
  return `${withZero(date.getHours())}:${withZero(date.getMinutes())}`;
};

type Props = {
  game: Game;
};

export const RoomItemView: FC<Props> = ({ game }) => {
  const hostName = game.players.find(
    (player) => player.userProfile.userId === game.host
  )?.userProfile.name;
  if (hostName == null) return null;
  return (
    <Root to={`/rooms/${game.id}`}>
      <Wrapper>
        <LabelAndValue label="テーマ" value={game.themeName} />
        <Players>
          参加者 {game.players.length} / {game.subjects.length}人
        </Players>
        <Footer>
          <div>ホスト {hostName}さん</div>
          <div>{toDateTimeText(game.createdAt)}</div>
        </Footer>
      </Wrapper>
    </Root>
  );
};

const Root = styled(Link)`
  text-decoration: none;
  color: initial;
  &:visited {
    color: initial;
  }
`;

const Wrapper = styled.div`
  border-radius: var(--radius-m);
  border: 2px solid var(--color-border);
  background-color: #fff;
  padding: var(--space-s);
`;

const Players = styled.div`
  margin-top: var(--space-s);
  padding: 0 var(--space-xs);
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 var(--space-xs);
`;
