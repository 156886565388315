import { FC } from "react";
import styled from "styled-components";

type Props = {
  remainingRate: number;
};

export const TimeView: FC<Props> = ({ remainingRate }) => {
  return (
    <Root>
      <Label>残り時間</Label>
      {0.01 < remainingRate && (
        <div>
          <TimeBar rate={remainingRate} />
        </div>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  align-items: center;
  padding: var(--space-s);
  margin-top: var(--space-s);
`;

const Label = styled.div`
  font-weight: bold;
  font-size: var(--font-size-s);
  text-align: center;
`;

const TimeBar = styled.div<{ rate: number }>`
  height: 0.8em;
  background-color: var(--color-primary);
  border-radius: var(--radius-m);
  border: 2px solid var(--color-border);
  width: ${({ rate }) => rate * 100}%;
  max-width: 100%;
  min-width: 0;
`;
