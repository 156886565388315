import { FC } from "react";
import { Dialog } from "../../element/Dialog";
import styled from "styled-components";
import { Text } from "../../element/Text";
import { Button } from "../../element/Button";

type Props = {
  subject: string;
  playerName: string;
  prompt: string;
  imageUrl: string;
  onClose: () => void;
};

export const ImageDialog: FC<Props> = ({
  subject,
  playerName,
  prompt,
  imageUrl,
  onClose,
}) => {
  return (
    <Dialog onClose={onClose}>
      <Wrapper>
        <img src={imageUrl} alt={subject} />
        <Text>「{subject}」</Text>
        <Text>{playerName}</Text>
      </Wrapper>
      <Prompt>{prompt}</Prompt>
      <Button onClick={onClose}>閉じる</Button>
    </Dialog>
  );
};

const Wrapper = styled.div`
  background-color: #fff;
  padding: var(--space-s);
  text-align: center;

  img {
    width: 100%;
    object-fit: contain;
    margin-bottom: var(--space-m);
  }
  ${Text} {
    font-weight: bold;
  }
`;

const Prompt = styled.div`
  background-color: #fff;
  padding: var(--space-s);
  margin: var(--space-m) 0;
  font-style: italic;
`;
