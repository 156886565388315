import styled from "styled-components";
import { FC, useEffect, useState } from "react";

const MAX_MESSAGE_COUNT = 4;

type Props = {
  messages: string[];
};

export const GameMessageView: FC<Props> = ({ messages }) => {
  const [visibleMessages, setVisibleMessages] = useState([
    "",
    "",
    "",
    "",
  ] as string[]);

  useEffect(() => {
    setVisibleMessages((prev) => {
      const next = prev.concat(messages);
      return MAX_MESSAGE_COUNT + 1 < next.length
        ? next.splice(next.length - MAX_MESSAGE_COUNT - 1)
        : next;
    });
  }, [messages]);

  return (
    <Root>
      {visibleMessages.map((message, index) => {
        return <Message key={message + index}>{message}</Message>;
      })}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: var(--space-xs);
  padding-bottom: var(--space-xs);
  align-items: start;
  gap: var(--space-xs);

  *:last-child {
    animation: 500ms message-in linear;
  }
  *:nth-last-child(2),
  :nth-last-child(3),
  :nth-last-child(4) {
    animation: 500ms message-move linear;
  }
  *:nth-last-child(n + 5) {
    animation: 500ms message-out linear forwards;
  }

  @keyframes message-move {
    0% {
      transform: translateY(100%);
    }
    100% {
    }
  }
  @keyframes message-in {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
    }
  }
  @keyframes message-out {
    0% {
      transform: translateY(100%);
    }
    100% {
      opacity: 0;
    }
  }
`;

const Message = styled.div<{ children: string }>`
  font-size: var(--font-size-xs);
  background-color: #33333388;
  padding: var(--space-xs);
  border-radius: var(--radius-xs);
  color: #fff;
  display: ${({ children }) => (children === "" ? "none" : "block")};
`;
