import { Dialog } from "../element/Dialog";
import { Button } from "../element/Button";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { auth } from "../firebase/config";
import { FC } from "react";
import { Text } from "../element/Text";
import styled from "styled-components";
import { Link } from "react-router-dom";

type Props = {
  text: string;
  onClose: () => void;
};

export const LoginDialog: FC<Props> = ({ text, onClose }) => {
  const onLoginButtonClick = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  return (
    <Dialog onClose={onClose}>
      <Wrapper>
        <Text>{text}</Text>
        <Hints>
          <Hint>初回ログイン時に チケット5枚を進呈！</Hint>
          <Hint>チケットは、ゲームのホストになるために必要です。</Hint>
          <Hint>
            ご利用には<Link to="/terms">利用規約</Link>と
            <Link to="/privacy-policy">プライバシーポリシー</Link>
            への同意が必要です。
          </Hint>
        </Hints>
        <Button onClick={onLoginButtonClick}>Googleでログイン</Button>
        <Button onClick={onClose}>やめる</Button>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
`;

const Hints = styled.div``;

const Hint = styled.div`
  font-size: var(--font-size-xs);
  text-align: center;
`;
