import styled from "styled-components";
import { FC } from "react";
import { useAdBlock } from "../hooks/useAdBlock";
import { Button } from "../element/Button";

export const Terms: FC = () => {
  useAdBlock();

  return (
    <Root>
      <Wrapper>
        <h1>利用規約</h1>
        <p>
          この利用規約（以下、「本規約」といいます。）は、ai-oekaki.jp（以下、「当サイト」といいます。）が提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。
          ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って、本サービスをご利用いただきます。
        </p>
        <h2>第1条（適用）</h2>
        <ol>
          <li>
            本規約は、ユーザーと当サイトとの間の本サービスの利用に関わる一切の関係に適用されるものとします。
          </li>
          <li>
            当サイトは本サービスに関し、本規約の他、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。
            これらの個別規定はその名称のいかんにかかわらず、本規約の一部を構成するものとします。
          </li>
          <li>
            本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
          </li>
        </ol>
        <h2>第2条（利用登録）</h2>
        <ol>
          <li>
            本サービスにおいては、登録希望者が本規約に同意の上、当サイトの定める方法によって利用登録を申請し、当サイトがこれを承認することによって、利用登録が完了するものとします。
          </li>
          <li>
            当サイトは、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
            <ol>
              <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
              <li>本規約に違反したことがある者からの申請である場合</li>
              <li>その他、当サイトが利用登録を相当でないと判断した場合</li>
            </ol>
          </li>
        </ol>
        <h2>第3条（生成物とプロンプトの扱い）</h2>
        <p>
          ユーザーが本サービスを利用して生成した画像およびその生成のために用いたプロンプトを、当サイトではユーザーへの事前の通知なく、以下の場合に使用できるものとします。
        </p>
        <ol>
          <li>本サービスの主たる機能またはそれに関連する機能を実現する場合</li>
          <li>
            本サービスの宣伝のため、Webメディア、出版社、テレビ局などの第三者へ提供する場合
          </li>
          <li>
            その他、ユーザーの一般の利益に適合すると当サイトが判断した場合
          </li>
        </ol>
        <h2>第4条（禁止事項）</h2>
        <p>
          ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。
        </p>
        <ol>
          <li>法令または公序良俗に違反する行為</li>
          <li>犯罪行為に関連する行為</li>
          <li>
            本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為
          </li>
          <li>
            当サイト、ほかのユーザー、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
          </li>
          <li>
            想定されない操作を繰り返したり、システムの不具合を意図的に引き起こしたりする行為
          </li>
          <li>本サービスによって得られた情報を商業的に利用する行為</li>
          <li>当サイトのサービスの運営を妨害するおそれのある行為</li>
          <li>不正アクセスをし、またはこれを試みる行為</li>
          <li>ほかのユーザーに関する個人情報等を収集または蓄積する行為</li>
          <li>不正な目的を持って本サービスを利用する行為</li>
          <li>
            本サービスのほかのユーザーまたはその他の第三者に不利益、損害、不快感を与える行為
          </li>
          <li>ほかのユーザーに成りすます行為</li>
          <li>
            当サイトが許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
          </li>
          <li>面識のない人物との出会いを目的とした行為</li>
          <li>
            当サイトのサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為
          </li>
          <li>その他、当サイトが不適切と判断する行為</li>
        </ol>
        <h2>第5条（本サービスの提供の停止等）</h2>
        <ol>
          <li>
            当サイトは、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
            <ol>
              <li>
                本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
              </li>
              <li>
                地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
              </li>
              <li>コンピュータまたは通信回線等が事故により停止した場合</li>
              <li>その他、当サイトが本サービスの提供が困難と判断した場合</li>
            </ol>
          </li>
          <li>
            当サイトは、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
          </li>
        </ol>
        <h2>第6条（利用制限および登録抹消）</h2>
        <ol>
          <li>
            当サイトは、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
            <ol>
              <li>本規約のいずれかの条項に違反した場合</li>
              <li>登録事項に虚偽の事実があることが判明した場合</li>
              <li>当サイトからの連絡に対し、一定期間返答がない場合</li>
              <li>本サービスについて、最終の利用から一定期間利用がない場合</li>
              <li>
                その他、当サイトが本サービスの利用を適当でないと判断した場合
              </li>
            </ol>
          </li>
          <li>
            当サイトは、本条に基づき当サイトが行った行為によりユーザーに生じた損害について、一切の責任を負いません。
          </li>
        </ol>
        <h2>第7条（退会）</h2>
        <p>
          ユーザーは、当サイトの定める退会手続きにより、本サービスから退会できるものとします。
        </p>
        <h2>第8条（保証の否認および免責事項）</h2>
        <ol>
          <li>
            当サイトは、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する血管、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
          </li>
          <li>
            当サイトは、本サービスに起因してユーザーに生じたあらゆる損害について、当サイトの故意または重過失による場合を覗き、一切の責任を負いません。
          </li>
          <li>
            当サイトは、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
          </li>
          <li></li>
        </ol>
        <h2>第9条（サービス内容の変更等）</h2>
        <p>
          当サイトは、ユーザーに事前に通知することなく、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。
        </p>
        <h2>第10条（個人情報の取り扱い）</h2>
        <p>
          当サイトは、本サービスの利用によって取得する個人情報については、当サイト「プライバシーポリシー」に従い適切に取り扱うものとします。
        </p>
        <h2>第11条（利用規約の変更）</h2>
        <ol>
          <li>
            当サイトは以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。
            <ol>
              <li>本規約の変更がユーザーの一般の利益に適合するとき。</li>
              <li>
                本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。
              </li>
            </ol>
          </li>
          <li>
            当サイトはユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨および変更後の本規約の内容ならびにその効力発生時期を通知します。
          </li>
        </ol>
        <h2>第12条（通知または連絡）</h2>
        <p>
          ユーザーと当サイトとの間の通知または連絡は、当サイトの定める方法によって行うものとします。当サイトは、ユーザーから、当サイトが別途定める方式に従った変更届出がない限り、現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い、これらは、発進時にユーザーへ到達したものとみまします。
        </p>
        <h2>第13条（権利義務の譲渡の禁止）</h2>
        <p>
          ユーザーは、当サイトの書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
        </p>
        <h2>第14条（準拠法・裁判管轄）</h2>
        <ol>
          <li>本規約の解釈にあたっては、日本法を準拠法とします。</li>
          <li>
            本サービスに関して紛争が生じた場合には、当サービス運営拠点を管轄する裁判所を専属的合意管轄とします。
          </li>
        </ol>
        <p style={{ textAlign: "right" }}>以上</p>
      </Wrapper>
      <Footer>
        <a href="/">
          <Button>ホームに戻る</Button>
        </a>
      </Footer>
    </Root>
  );
};

const Root = styled.div`
  height: 100svh;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  padding: var(--space-l);
  overflow: auto;
`;

const Footer = styled.div`
  padding: var(--space-l);
  flex-grow: 1;

  a {
    text-decoration: none;
    outline: none;

    &:active,
    &:target,
    &:focus-visible {
      background-color: transparent;
      border: none;
    }
  }
`;
