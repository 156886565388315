import React, { FC } from "react";
import { FullscreenLoading } from "./components/FullscreenLoading";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./pages/Home";
import { Room } from "./pages/Room";
import { GameResult } from "./pages/GameResult";
import { Terms } from "./pages/Terms";
import { Rooms } from "./pages/Rooms";
import { useUserInitialization } from "./hooks/useUserInitialization";
import { useUserProfileInitialization } from "./hooks/useUserProfileInitialization";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";

export const App: FC = () => {
  const user = useUserInitialization();
  const userProfile = useUserProfileInitialization(user);

  if (userProfile === "loading") return <FullscreenLoading />;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/rooms/:gameId" element={<Room />} />
        <Route path="/rooms/:gameId/result" element={<GameResult />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
};
