import { User } from "firebase/auth";
import { Account } from "../models/Account";
import { doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { db } from "../firebase/config";

export const accountRepository = {
  listen(
    user: User,
    listener: (account: Account | undefined) => void
  ): Unsubscribe {
    return onSnapshot(doc(db, `accounts/${user.uid}`), (docSnapshot) => {
      const data = docSnapshot.data();
      if (data == null) {
        listener(undefined);
        return;
      }

      listener({
        tickets: data.tickets,
      });
    });
  },
};
