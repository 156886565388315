import { FC } from "react";
import { FullscreenLoading } from "./FullscreenLoading";
import { Dialog } from "../element/Dialog";
import styled from "styled-components";

export type Props = {
  isVisible: boolean;
  message?: string;
};

export const LoadingDialog: FC<Props> = ({ isVisible, message }) => {
  if (!isVisible) return null;
  return (
    <Dialog>
      <Wrapper>
        <FullscreenLoading message={message} />
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled.div`
  padding: var(--space-l) var(--space-m);
`;
