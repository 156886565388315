import { UserProfile } from "../models/UserProfile";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { User } from "firebase/auth";

export const userProfileRepository = {
  async create(userProfile: Omit<UserProfile, "id">): Promise<UserProfile> {
    const doc = await addDoc(collection(db, "userProfiles"), userProfile);
    return { ...userProfile, id: doc.id };
  },

  async update(userProfile: UserProfile): Promise<UserProfile> {
    await updateDoc(
      doc(db, "userProfiles", userProfile.id),
      userProfile as Omit<UserProfile, "id">
    );
    return userProfile;
  },

  async findByUser(user: User): Promise<UserProfile | undefined> {
    const q = query(
      collection(db, "userProfiles"),
      where("userId", "==", user.uid)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return;

    const doc = querySnapshot.docs[0];
    const data = doc.data();
    return {
      id: doc.id,
      userId: data.userId,
      name: data.name,
      tutorialFinishedAt: data.tutorialFinishedAt?.toDate() ?? null,
    };
  },
};
