import { Game } from "../models/Game";
import { getBlob, ref } from "firebase/storage";
import { storage } from "../firebase/config";

export const imageRepository = {
  async getDealersImageUrl(game: Game): Promise<string | undefined> {
    const imagePath = game.imagePaths[game.dealerIndex];
    if (imagePath == null || imagePath === "") return;

    return this.getImageUrlByPath(imagePath);
  },

  async getAllImageUrls(game: Game): Promise<string[]> {
    const urls = [];
    for (const imagePath of game.imagePaths) {
      if (imagePath === "") {
        urls.push("");
        continue;
      }

      urls.push(await this.getImageUrlByPath(imagePath));
    }
    return urls;
  },

  async getImageUrlByPath(imagePath: string): Promise<string> {
    const pathReference = ref(storage, imagePath);
    const blob = await getBlob(pathReference);
    return URL.createObjectURL(blob);
  },
};
