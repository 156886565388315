import { FC } from "react";
import styled from "styled-components";

type Props = {
  label: string;
  value: string;
};

export const LabelAndValue: FC<Props> = ({ label, value }) => {
  return (
    <Root>
      <LabelView>{label}</LabelView>
      <ValueView>{value}</ValueView>
    </Root>
  );
};

const Root = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  grid-gap: var(--space-m);
  align-items: center;

  padding: var(--space-xs);
  font-size: var(--font-size-l);
  font-weight: bold;
`;

const LabelView = styled.div`
  border-radius: 4px;
  border: 2px solid #666;
  background-color: #fc0;
  padding: var(--space-xs);
  text-align: center;
`;

const ValueView = styled.div``;
