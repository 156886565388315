import { FC } from "react";
import { TitleView } from "../components/TitleView";
import styled from "styled-components";
import { AdContainer } from "../components/AdContainer";
import {
  FacebookIcon,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "next-share";
import { RobotMessage } from "../components/home/RobotMessage";
import { Footer } from "../components/home/Footer";
import { Link } from "react-router-dom";
import { Button } from "../element/Button";
import { AccountView } from "../components/AccountView";
import { TutorialDialog } from "../components/tutorialDialog";

export const Home: FC = () => {
  return (
    <Root>
      <AccountView />
      <TitleView />
      <GameDescription>
        <img src="/icon192.png" alt="ゲームイメージ" />
        <RobotMessage value="AIに絵を描かせて、なんの絵か当てるゲームです。" />
      </GameDescription>
      <ButtonsArea>
        <Link to="/rooms">
          <Button>みんなで遊ぶ</Button>
        </Link>
        <div>
          <div
            style={{
              fontSize: "var(--font-size-s)",
              textAlign: "center",
              marginTop: "var(--space-m)",
              marginBottom: "0",
            }}
          >
            近日公開予定
          </div>
          <Button disabled={true}>ひとりで遊ぶ</Button>
        </div>
        <SocialButtons>
          <TwitterShareButton
            url={window.location.href}
            hashtags={[window.document.title]}
          >
            <TwitterIcon round size="48" />
          </TwitterShareButton>
          <FacebookShareButton
            url={window.location.href}
            hashtag={window.document.title}
          >
            <FacebookIcon round size="48" />
          </FacebookShareButton>
          <LineShareButton
            url={window.location.href}
            title={window.document.title}
          >
            <LineIcon round size="48" />
          </LineShareButton>
        </SocialButtons>
      </ButtonsArea>
      <Footer />
      <TutorialDialog />
    </Root>
  );
};

const Root = styled(AdContainer)``;

const GameDescription = styled.div`
  display: flex;
  gap: var(--space-m);
  margin: var(--space-xl) 0;

  img {
    width: 6rem;
    object-fit: contain;
  }
`;

const ButtonsArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  margin: 0 var(--space-l);
`;

const SocialButtons = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-m);
  margin-top: var(--space-m);
  align-self: center;
`;
