import { FC } from "react";
import styled from "styled-components";
import { Text } from "../../element/Text";
import { Dialog } from "../../element/Dialog";
import { Button } from "../../element/Button";

type Props = {
  onGoToResultButtonClick: () => void;
};

export const FinishedMessageDialog: FC<Props> = ({
  onGoToResultButtonClick,
}) => {
  return (
    <Dialog>
      <FinishedMessage>
        <h2>ゲーム終了！</h2>
        どれも素晴らしい作品でした！
        <br />
        次の画面で結果発表です
      </FinishedMessage>
      <Button onClick={onGoToResultButtonClick}>結果を見る</Button>
    </Dialog>
  );
};

const FinishedMessage = styled(Text)`
  margin-bottom: var(--space-l);
  text-align: center;
`;
