import { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const Footer: FC = () => {
  return (
    <Root>
      <Links>
        <Link to="/terms">利用規約</Link>
        <Link to="/privacy-policy">プライバシーポリシー</Link>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSf-8-rhZPSI8DrpjCRQD5UvAMziOifD4C-as_iTVB_AsEku3Q/viewform"
          target="_blank"
          rel="noreferrer noopener"
        >
          お問合せ
        </a>
      </Links>
      <div style={{ marginTop: "8px" }}>&copy; 2023 ai-oekaki.jp</div>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--space-l);
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
