import { FC } from "react";
import { Dialog } from "../../element/Dialog";
import styled from "styled-components";
import { MultiPlayerGameRule } from "../MultiPlayerGameRule";
import { Button } from "../../element/Button";

type Props = {
  onClose: () => void;
};

export const MultPlayerGameRuleDialog: FC<Props> = ({ onClose }) => {
  return (
    <Dialog onClose={onClose}>
      <Wrapper>
        <MultiPlayerGameRule />
        <Button onClick={onClose}>閉じる</Button>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
