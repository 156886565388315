import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Game } from "../../models/Game";
import { FullscreenLoading } from "../FullscreenLoading";
import { imageRepository } from "../../repositories/ImageRepository";

type Props = {
  game: Game;
};

export const ImageView: FC<Props> = ({ game }) => {
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    imageRepository.getDealersImageUrl(game).then((url) => setImageUrl(url));
  }, [game.imagePaths, setImageUrl]);

  return (
    <Root>
      {imageUrl != null ? (
        <img width="512" height="512" src={imageUrl} />
      ) : (
        <img width="512" height="512" />
      )}
      {game.state === "prompting" && (
        <FullscreenLoading message="画伯が考え中です" />
      )}
      {game.state === "generating" && (
        <FullscreenLoading message="AIが絵を描いています" />
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  border: 2px solid #666;
  border-radius: 8px;
  background-color: #fff;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
  }
`;
