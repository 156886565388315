import { FC, useState } from "react";
import styled from "styled-components";
import { User } from "firebase/auth";
import { Game } from "../../models/Game";
import { SmallButton } from "../../element/SmallButton";
import { TextField } from "../../element/TextField";

type Props = {
  user: User;
  game: Game;
  onSubmit: (value: string) => void;
};

export const GameInput: FC<Props> = ({ user, game, onSubmit }) => {
  const [value, setValue] = useState("");

  const isDealer =
    game.players[game.dealerIndex].userProfile.userId === user.uid;
  const isInputEnabled =
    (isDealer && game.state === "prompting") ||
    (!isDealer && game.state === "thinking");

  const submit = async () => {
    setValue("");
    onSubmit(value);
  };

  if (game.state === "finished") return null;
  return (
    <Root>
      <Input
        type="text"
        maxLength={100}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <SmallButton
        onClick={submit}
        disabled={value.trim() === "" || !isInputEnabled}
      >
        {isDealer ? "生成" : "回答"}
      </SmallButton>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  background: #00000033;
  padding: 8px 16px 16px 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  gap: 8px;
`;

const Input = styled(TextField)`
  flex-grow: 1;
`;
