import { FC, HTMLProps } from "react";
import styled from "styled-components";
import { useAdHeight } from "../hooks/useAdHeight";

export const AdContainer: FC<HTMLProps<HTMLDivElement>> = ({
  className,
  children,
}) => {
  const adHeight = useAdHeight();
  return (
    <Root className={className} $adHeight={adHeight}>
      {children}
    </Root>
  );
};

const Root = styled.div<{ $adHeight: number }>`
  overflow: auto;
  padding: var(--space-l);
  height: calc(100svh - 2 * var(--space-l) - ${({ $adHeight }) => $adHeight}px);
`;
