import { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

type Props = {
  pages: { name: string; path?: string }[];
};

export const Breadcrumbs: FC<Props> = ({ pages = [] }) => {
  return (
    <Root>
      <Link to="/">ホーム</Link>
      {pages.map((page) => (
        <Item key={page.path + page.name}>
          <span>≫</span>
          {page.path != null ? (
            <Link to={page.path}>{page.name}</Link>
          ) : (
            <span>{page.name}</span>
          )}
        </Item>
      ))}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  gap: var(--space-s);
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  gap: var(--space-s);
`;
