import { FC, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { ImageView } from "./ImageView";
import { TimeView } from "./TimeView";
import { FinishedMessageDialog } from "./FinishedMessageDialog";
import { PlayersView } from "./PlayersView";
import { CorrectEffect } from "./CorrectEffect";
import { GameFooter } from "./GameFooter";
import { Game } from "../../models/Game";
import { useAdBlock } from "../../hooks/useAdBlock";
import { useWakeLock } from "../../hooks/useWakeLock";
import { LabelAndValue } from "../LabelAndValue";
import { useUser } from "../../hooks/userUser";

const PROMPTING_TIME_LIMIT = 60_000;

const THINKING_TIME_LIMIT = 40_000;

const COUNT_STEP = 1000;

type Props = {
  game: Game;
  gameManager: {
    postPrompt: (text: string) => Promise<void>;
    postAnswer: (text: string) => Promise<void>;
    skip: () => Promise<void>;
  };
};

export const GameView: FC<Props> = ({ game, gameManager }) => {
  useAdBlock();
  useWakeLock();

  const user = useUser();
  const [remainingMillis, setRemainingMillis] = useState(PROMPTING_TIME_LIMIT);

  const dealer = useMemo(() => {
    const dealer = game.players[game.dealerIndex];
    return dealer.userProfile;
  }, [game]);

  const isDealer = useMemo(() => dealer.userId === user.uid, [dealer, user]);

  // タイマーのカウント
  useEffect(() => {
    if (game.state === "generating" || game.state === "finished") return;
    const timerId = window.setInterval(() => {
      setRemainingMillis((prev) => prev - COUNT_STEP);
    }, COUNT_STEP);
    return () => window.clearInterval(timerId);
  }, [isDealer, game.state]);

  // 初期タイマーの設定
  useEffect(() => {
    const diff =
      game.timerStartedAt?.getTime() != null
        ? Date.now() - game.timerStartedAt?.getTime()
        : 0;
    setRemainingMillis(
      (game.state === "prompting"
        ? PROMPTING_TIME_LIMIT
        : THINKING_TIME_LIMIT) - diff
    );
  }, [isDealer, game.timerStartedAt]);

  // スキップ処理
  useEffect(() => {
    if (!isDealer) return;
    if (-COUNT_STEP <= remainingMillis && remainingMillis <= 0) {
      if (game.state === "prompting") {
        gameManager.postPrompt("");
      } else {
        gameManager.skip();
      }
    }
  }, [remainingMillis, isDealer]);

  const onSubmit = async (text: string) => {
    if (isDealer && game.state === "prompting" && 0 < remainingMillis) {
      await gameManager.postPrompt(text);
    } else if (!isDealer && game.state === "thinking") {
      await gameManager.postAnswer(text);
    }
  };

  const onGoToResultButtonClick = () => {
    window.location.href = `/rooms/${game.id}/result`;
  };

  return (
    <Root>
      <LabelAndValue label="テーマ" value={game.themeName} />
      <LabelAndValue
        label="お題"
        value={
          dealer.userId === user.uid
            ? game.subjects[game.dealerIndex].name
            : "？？？"
        }
      />
      <TimeView
        remainingRate={
          remainingMillis /
          (game.state === "prompting"
            ? PROMPTING_TIME_LIMIT
            : THINKING_TIME_LIMIT)
        }
      />
      <ImageArea>
        <ImageView game={game} />
      </ImageArea>
      <PlayersView user={user} game={game} />
      {game.state === "finished" && (
        <FinishedMessageDialog
          onGoToResultButtonClick={onGoToResultButtonClick}
        />
      )}
      {game.messages.find((m) => m.includes("正解")) != null && (
        <CorrectEffect subject={game.subjects[game.dealerIndex].name} />
      )}
      <GameFooter user={user} game={game} onSubmit={onSubmit} />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--space-l);
  padding-bottom: 6rem;
`;

const ImageArea = styled.div`
  margin: var(--space-m) 0;
  padding: var(--space-m);
`;
