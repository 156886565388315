import { FC } from "react";
import styled from "styled-components";

type Props = {
  subject: string;
};

export const CorrectEffect: FC<Props> = ({ subject }) => {
  return (
    <Root>
      <Ch $index={0}>正</Ch>
      <Ch $index={1}>解</Ch>
      <br />
      {subject.split("").map((ch, index) => (
        <Ch key={ch + index} $index={index + 2}>
          {ch}
        </Ch>
      ))}
    </Root>
  );
};

const Root = styled.div`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 900;
  color: red;
  text-shadow: -2px -2px 2px #fff, 2px -2px 2px #fff, -2px 2px 2px #fff,
    2px 2px 2px #fff;
  position: absolute;
  top: var(--space-xl);
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
`;

const Ch = styled.span<{ $index: number }>`
  display: inline-block;
  animation: in 200ms linear ${({ $index }) => $index * 50}ms forwards,
    anim 200ms linear ${({ $index }) => $index * 50 + 150}ms forwards,
    out 400ms linear 3500ms forwards;
  transform: translateX(-20rem);
  @keyframes in {
    0% {
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes anim {
    0% {
      scale: 1;
    }
    25% {
      scale: 0.2;
    }
    50% {
      opacity: 0.2;
    }
    75% {
      scale: 2.5;
    }
    100% {
      scale: 1;
      opacity: 1;
    }
  }
  @keyframes out {
    0% {
    }
    100% {
      opacity: 0;
    }
  }
`;
