import styled from "styled-components";

export const TextField = styled.input`
  font-size: 1.2em;
  outline: none;
  padding: var(--space-s) var(--space-m);
  border-radius: var(--space-l);
  border: 2px solid #666;
  background-color: #fff;
  min-width: 0;

  &:disabled {
    background-color: #eee;
  }
`;
