import { useEffect } from "react";

export const useAdBlock = () => {
  useEffect(() => {
    const target = document.getElementById("nend_adspace_408831_1158729");
    if (target == null) return;

    hideAd();
    const observer = new MutationObserver((mutations, observer) => {
      hideAd();
      observer.disconnect();
    });

    observer.observe(target, {
      attributes: true,
    });
  }, []);
};

const hideAd = () => {
  // @ts-ignore
  const obj = window["nendOlObject"];
  if (obj == null) return;
  obj.hide();
  obj.redraw = () => {};
};
