import { Theme } from "../models/Theme";
import { useEffect, useState } from "react";
import { themeRepository } from "../repositories/ThemeRepository";

export const useThemes = (): Theme[] => {
  const [themes, setThemes] = useState<Theme[]>([]);
  useEffect(() => {
    themeRepository.findAll().then((themes) => {
      setThemes(themes);
    });
  }, []);
  return themes;
};
