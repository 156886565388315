import { Player } from "../../models/Player";
import { FC } from "react";
import styled from "styled-components";

type Props = {
  players: Player[];
};

export const RankingView: FC<Props> = ({ players }) => {
  const rankedPlayers = [...players].sort((a, b) => b.score - a.score);
  return (
    <Root>
      {rankedPlayers.map((player, index) => (
        <Item key={player.userProfile.id}>
          <div>{index === 0 ? "優勝" : `${index + 1}位`}</div>
          <div>{player.userProfile.name}</div>
          <div>{player.score}点</div>
        </Item>
      ))}
    </Root>
  );
};

const Root = styled.div`
  border-radius: 4px;
  border: 2px solid #666;
  background-color: #fff;

  > * {
    padding: 8px 16px;
    font-weight: bold;
    font-size: 1.4em;
  }
`;

const Item = styled.div`
  display: grid;
  grid-template-columns: 25% 55% 20%;
`;
